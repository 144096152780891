import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'

const GUIDE_COORDINATES_QUERY = gql`
  query guide($guideId: Int!) {
    guide(id: $guideId) {
      id
      latitude
      longitude
    }
  }
`

export const useGuideCoordinates = () => {
  const { data: guideData, loading } = useQuery(GUIDE_COORDINATES_QUERY)
  const guideLatitude: number | undefined = guideData?.guide?.latitude
  const guideLongitude: number | undefined = guideData?.guide?.longitude

  return { guideLatitude, guideLongitude, loading }
}
