import * as React from 'react'
import { t } from 'client/i18n'
import DividerComponent from 'client/components/Divider/Divider'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { getPinMovementMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/shared'

const Divider = styled(DividerComponent)`
  margin-top: var(--spacing-small);
  margin-bottom: var(--spacing-small);
`

interface IConfirmation {
  title: React.ReactNode
  message: React.ReactNode
}

export const getBuildingCreateConfirmationMessage = (): IConfirmation => {
  const message = (
    <>
      <p>{t('Before you add multiple buildings, you need to include the exterior map.')}</p>
      <>
        <Divider />
        <p>
          <Trans i18nKey="addExteriorMapWarning" />
        </p>
      </>
    </>
  )

  const title = t('Add an exterior map to support multiple buildings')
  return { message, title }
}

export const getExteriorMapCreateConfirmationMessage = (): IConfirmation => {
  const message = (
    <p>
      <Trans i18nKey="addExteriorMapSuggestion" />
    </p>
  )

  const title = t('Add Exterior Map')

  return { message, title }
}

export const getSuccessConfirmationMessage = (
  isGoogleMap: boolean,
  hasBuildings: boolean,
  isGoogleMapsFeatureFlagEnabled: boolean
): IConfirmation => ({
  title: t('Success'),
  message: (
    <>
      <p>{t('The exterior map has been added.')}</p>
      {hasBuildings && (
        <p>
          {t('A building pin has been added to the exterior map to represent your first building.')}
          {` ${getPinMovementMessage(isGoogleMap, isGoogleMapsFeatureFlagEnabled)}`}
        </p>
      )}
    </>
  )
})
