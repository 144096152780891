import _ from 'lodash'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import {
  FILE_NAME_COLUMN,
  FLEXIBLE_COLUMN_SMALL_PROPS,
  LANGUAGES_COLUMN,
  MEDIA_COLUMN_SPACING,
  UPDATED_AT_COLUMN,
  DOWNLOAD_COLUMN,
  MEDIA_INCLUDED_IN_COLUMN,
  MEDIA_INCLUDED_IN_FILTER_CRITERIA
} from 'client/screens/Catalog/grids/shared/columns'
import { IColumnProps, ITableCellProps } from 'client/dsm/Table/Table'
import { SupportedVideoTypes } from 'client/constants'
import { UploadMediaType } from 'client/hooks/useMediaUpload'
import { t } from 'client/i18n'
import ClosedCaptionLabelTooltip from 'client/components/Languages/ClosedCaptionLabelTooltip'
import { GQLVideo } from 'shared/graphql/types/graphql'
import { formatDurationInHoursMinutesSeconds } from 'client/util/dates'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { Header3 } from 'client/components/TextStyles'
import EmptyStateGridVideoSVG from 'client/assets/svg/emptyStates/empty_state_grid_video.svg'
import VideoPreview from './renderers/VideoPreview'
import GridPlusIconAddButton from './shared/GridPlusIconAddButton'
import VideoForm from '../forms/VideoForm'
import {
  EmptyStatePlaceholderDescription,
  EmptyStatePlaceholderDragDropFooter
} from './shared/styledComponents'

// Note: Assumes duration is less than day.
const durationValue = ({ cellData }: ITableCellProps<any>) => {
  return cellData && formatDurationInHoursMinutesSeconds(cellData)
}

const CC_AD_COLUMN: IColumnProps = {
  dataKey: 'translations',
  label: t('CC'),
  cellRenderer: ({ cellData: translations }) => {
    return <ClosedCaptionLabelTooltip translations={translations} isPopperInteractive={false} />
  },
  sortBy: ({ translations }: GQLVideo) => {
    return _.some(translations, 'videoSubtitles')
  }
}

const VIDEOS_GRAPHQL_QUERY = gql`
  query videosForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      videos {
        id
        url
        sourceUrl
        size
        title
        fileName
        duration
        isProcessing
        externalId
        isChildrenProcessingError
        posterImageUrl
        translations {
          locale {
            id
            englishName
            code
          }
          videoSubtitles {
            id
          }
        }
        locales {
          id
          code
          englishName
        }
        updatedAt
        isMarketingUseAllowed
        items {
          title
          commonName
          scientificName
          creators {
            name
          }
        }
        exhibits {
          title
        }
      }
    }
  }
`
const addVideoButton = <GridPlusIconAddButton label={t('Add Video')} to="/catalog/videos/new" />

const VideosEmptyPlaceholder = () => (
  <>
    <Header3>{t('Upload Video Files')}</Header3>
    <EmptyStatePlaceholderDescription centerAlign={true}>
      <p>{t('Add short videos to use in your Items, Exhibitions, Tours, and Events.')}</p>
      <EmptyStatePlaceholderDragDropFooter addButtonNode={addVideoButton} />
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Videos() {
  const { MARKETING } = useFeatureFlags()
  return (
    <BaseGridView
      contentName="Video"
      gqlQuery={VIDEOS_GRAPHQL_QUERY}
      columns={_.compact([
        {
          dataKey: 'thumbnail',
          label: t('Preview'),
          cellRenderer: VideoPreview,
          minWidth: 140,
          maxWidth: 140,
          ...MEDIA_COLUMN_SPACING,
          sortable: false
        },
        { dataKey: 'title', label: t('Title') },
        FILE_NAME_COLUMN,
        CC_AD_COLUMN,
        {
          dataKey: 'duration',
          label: t('Duration'),
          cellRenderer: durationValue,
          ...FLEXIBLE_COLUMN_SMALL_PROPS
        },
        MEDIA_INCLUDED_IN_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        MARKETING && DOWNLOAD_COLUMN
      ])}
      filterCriteria={['title', 'fileName', ...MEDIA_INCLUDED_IN_FILTER_CRITERIA]}
      buttons={addVideoButton}
      formComponent={VideoForm}
      uploadMediaType={UploadMediaType.VIDEO}
      droppableFileFormats={SupportedVideoTypes}
      placeholder={<VideosEmptyPlaceholder />}
      placeholderIcon={<EmptyStateGridVideoSVG />}
    />
  )
}
