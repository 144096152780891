import { t } from 'client/i18n'

const PIN_MESSAGES = {
  IS_GOOGLE_MAPS_FEATURE_FLAG_ENABLED: t(
    'To move the building’s pin, drag and drop this pin or adjust the latitude and longitude coordinates.'
  ),
  IS_GOOGLE_MAPS_FEATURE_FLAG_DISABLED: t(
    'To move the building’s pin, adjust the latitude and longitude coordinates.'
  ),
  IS_GOOGLE_MAPS_TOGGLED_OFF: t('Drag and drop this pin to change the location.')
}

export const getPinMovementMessage = (
  isGoogleMap: boolean,
  isGoogleMapsFeatureFlagEnabled: boolean
): string => {
  if (!isGoogleMap) {
    return PIN_MESSAGES.IS_GOOGLE_MAPS_TOGGLED_OFF
  }
  return isGoogleMapsFeatureFlagEnabled
    ? PIN_MESSAGES.IS_GOOGLE_MAPS_FEATURE_FLAG_ENABLED
    : PIN_MESSAGES.IS_GOOGLE_MAPS_FEATURE_FLAG_DISABLED
}
