/* eslint-disable no-alert, i18next/no-literal-string, docent/no-plain-text-jsx-attributes */
import Button from 'client/components/Button/Button'
import FormField from 'client/components/Form/FormField/FormField'
import StringInput from 'client/components/Form/StringInput/StringInput'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import PageContent from 'client/components/PageContent/PageContent'
import api from 'client/util/api'
import { useState } from 'react'
import styled from 'styled-components'
import { APPSFLYER_DEEP_LINK_BASE_URL } from 'shared/constants/appsflyer'

const Main = styled.main`
  padding: var(--spacing-xlarge);
`

const TextArea = styled.textarea`
  width: 100%;
  padding: var(--spacing-xsmall);
`

/*
  this represents a super basic state machine where some logic here wants to know "have I at least
  reached this step in the workflow yet?". Where the workflow is:

    1. start
    2. lookup params for link
    3. edit the params json
    4. validate the json
    5. make the update in appsflyer & db
    6. end

  I figured this was a little nicer than having a separate useState var for each of the things we're
  trying to keep track of here. And all of this was intended to keep the tool as foolproof as
  possible and prevent error states like editing the params while also changing the value in the
  link text input. I wanted to enforce the linear flow above
*/
enum Step {
  start,
  fetchingParams,
  urlLockedIn,
  paramsValidated,
  updatingParams,
  end
}

export default function AppsflyerLinkEditor() {
  const [url, setUrl] = useState('')
  const [paramsString, setParamsString] = useState('')

  const [step, setStep] = useState(Step.start)
  const urlLockedIn = step >= Step.urlLockedIn
  const paramsHaveBeenValidated = step >= Step.paramsValidated

  return (
    <PageContent title="Appsflyer Link Editor">
      <Main>
        {[Step.fetchingParams, Step.updatingParams].includes(step) ? (
          <LoadingOverlay />
        ) : (
          <>
            <FormField label="Link">
              <StringInput
                value={url}
                disableAutocomplete={true}
                disabled={urlLockedIn}
                placeholder={`${APPSFLYER_DEEP_LINK_BASE_URL}:slug`}
                onChange={(e) => {
                  setUrl(e.target.value.trim())
                }}
                onKeyDown={async (e) => {
                  if (e.key !== 'Enter') {
                    return
                  }

                  // Enter key was pressed
                  if (!url.startsWith(APPSFLYER_DEEP_LINK_BASE_URL)) {
                    alert(`❌ Link must start with ${APPSFLYER_DEEP_LINK_BASE_URL}`)
                    return
                  }

                  setStep(Step.fetchingParams)
                  try {
                    const response = await api.get<Record<string, string>>(
                      `/api/developer/get-appsflyer-link-params/${encodeURIComponent(url)}`,
                      { validateStatus: null }
                    )

                    if (response.status !== 200) {
                      throw new Error(response.data.message)
                    }

                    const { data } = response
                    setParamsString(JSON.stringify(data, Object.keys(data).sort(), 2))
                    setStep(Step.urlLockedIn)
                  } catch (err) {
                    alert(`❌ ${err}`)
                    setParamsString('')
                    setStep(Step.start)
                  }
                }}
              />
            </FormField>

            {urlLockedIn && (
              <>
                <FormField label="Params">
                  <TextArea
                    rows={16}
                    value={paramsString}
                    disabled={paramsHaveBeenValidated}
                    wrap="off"
                    onChange={(e) => {
                      setParamsString(e.target.value)
                    }}
                  />
                  <Button
                    label="Validate JSON"
                    type="primary"
                    disabled={paramsHaveBeenValidated}
                    onClick={() => {
                      // TODO could be nice to have this do yup validations eventually
                      //      maybe even show a diff-view between old and new params
                      try {
                        const params = JSON.parse(paramsString)
                        if (typeof params !== 'object') {
                          throw new Error('invalid JSON')
                        }
                        setParamsString(JSON.stringify(params, null, 2))
                        setStep(Step.paramsValidated)
                      } catch (e) {
                        alert(`❌ ${e}`)
                      }
                    }}
                  />
                </FormField>
                {paramsHaveBeenValidated && (
                  <Button
                    label="Update Appsflyer Link Params"
                    type="primary"
                    disabled={step >= Step.end}
                    onClick={async () => {
                      if (
                        window.confirm(
                          '👀 Are you sure you want to update this link to use these params?'
                        )
                      ) {
                        try {
                          const params = JSON.parse(paramsString)
                          setStep(Step.updatingParams)
                          await api.post('/api/developer/update-appsflyer-link-params', {
                            url,
                            params
                          })
                          setStep(Step.end)
                          alert('✅ Successfully updated link params!')
                        } catch (e) {
                          alert(`❌ ${e}`)
                          setStep(Step.paramsValidated)
                        }
                      }
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
      </Main>
    </PageContent>
  )
}
