import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const FIRST_OPENS_QUERY = gql`
  query firstOpens {
    firstOpensReport {
      date
      platform
      firstRuns
    }
  }
`

const Placeholder = () => (
  <>
    <p>{t('No First Opens have been added yet.')}</p>
    <p>
      <Trans i18nKey="Click __addText__ to continue." values={{ addText: t('Add First Open') }} />
    </p>
  </>
)

// TODO: do these reports even work any more?
export default function FirstOpens() {
  return (
    <BaseGridView
      contentName="First Open"
      gqlQuery={FIRST_OPENS_QUERY}
      columns={[
        { dataKey: 'date', label: t('Date') },
        { dataKey: 'platform', label: t('Platform') },
        { dataKey: 'firstRuns', label: t('First Opens') }
      ]}
      placeholder={<Placeholder />}
    />
  )
}
