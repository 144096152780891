/* eslint-disable i18next/no-literal-string */
import _ from 'lodash'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import GridPlusIconAddButton from 'client/screens/Catalog/grids/shared/GridPlusIconAddButton'
import {
  EXHIBIT_DATE_AND_TIME_COLUMN,
  EXHIBIT_OVERFLOW_MENU_COLUMN,
  htmlRenderer,
  ITEM_EXHIBIT_IMAGE_COLUMN,
  LANGUAGES_COLUMN,
  UPDATED_AT_COLUMN,
  VISIBILITY_COLUMN
} from 'client/screens/Catalog/grids/shared/columns'
import { GQLExhibit } from 'shared/graphql/types/graphql'
import { ExhibitionType } from 'shared/constants/exhibits'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import EmptyStateGridEventSVG from 'client/assets/svg/emptyStates/empty_state_grid_event.svg'
import EventForm from '../forms/EventForm'
import { EmptyStatePlaceholderDescription } from './shared/styledComponents'

const EXHIBITS_GRAPHQL_QUERY = gql`
  query exhibitsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      exhibits {
        id
        type
        uuid
        published
        title
        displayPeriod
        from
        to
        timezone
        isAllDay
        externalId
        images {
          hero
        }
        locales {
          code
          englishName
        }
        updatedAt
      }
    }
  }
`
const addEventButton = <GridPlusIconAddButton label={t('Add Event')} to="/catalog/events/new" />

const EventsEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add your first event')}</Header3>
    <EmptyStatePlaceholderDescription>
      <p>
        {t(
          'An event is any programming that occurs on a specific day or at a specific time, like:'
        )}
      </p>
      <ul>
        <li>{t('An installment in a speaker series')}</li>
        <li>{t('An artist, craft, and makers fair')}</li>
        <li>{t('A film screening or performance')}</li>
      </ul>
      {addEventButton}
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Events() {
  return (
    <BaseGridView
      contentName="Event"
      gqlQuery={EXHIBITS_GRAPHQL_QUERY}
      // Probably want to do this on the server in the future
      gqlFilterFunction={(exhibit: GQLExhibit) => exhibit.type === ExhibitionType.EVENT}
      columns={_.compact([
        ITEM_EXHIBIT_IMAGE_COLUMN,
        { dataKey: 'title', label: t('Title'), cellRenderer: htmlRenderer },
        EXHIBIT_DATE_AND_TIME_COLUMN,
        VISIBILITY_COLUMN,
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN,
        EXHIBIT_OVERFLOW_MENU_COLUMN
      ])}
      filterCriteria={['title', 'information']}
      buttons={addEventButton}
      formComponent={EventForm}
      placeholder={<EventsEmptyPlaceholder />}
      placeholderIcon={<EmptyStateGridEventSVG />}
    />
  )
}
