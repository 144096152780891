// No need for translations here, it's purely for marketing use */
/* eslint-disable i18next/no-literal-string, docent/no-plain-text-jsx-attributes */
import { Dialog, DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'

interface IGuideConfirmationDialogForPdfUploadProps {
  guideName: string
  onClose: () => void
  onUpload: () => void
}

function GuideConfirmationDialogForPdfUpload({
  guideName,
  onClose,
  onUpload
}: IGuideConfirmationDialogForPdfUploadProps) {
  return (
    <Dialog>
      <DialogHeader>{`Upload PDF for ${guideName}`}</DialogHeader>
      <DialogBody>
        <p>
          You are about to upload a PDF to generate CSV output and QR codes for{' '}
          <strong>{`${guideName}`}</strong>.
        </p>
        <p>
          If <strong>{`${guideName}`}</strong> is not the correct guide, click{' '}
          <strong>Cancel</strong> and select the correct guide in the guide selector at the top left
          of the page.
        </p>
      </DialogBody>
      <DialogActions>
        <Button onClick={onClose} label="Cancel" />
        <Button
          type="primary"
          onClick={() => {
            onClose()
            onUpload()
          }}
          label="Upload"
        />
      </DialogActions>
    </Dialog>
  )
}

export default GuideConfirmationDialogForPdfUpload
