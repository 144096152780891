import { Route, Routes } from 'react-router-dom'
import GuideStarts from 'client/screens/Internal/GuideStarts'
import FirstOpens from 'client/screens/Internal/FirstOpens'
import AssetCounts from 'client/screens/Internal/AssetCounts'
import QrCodeGeneration from 'client/screens/QrCodeGeneration'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

export default function Internal() {
  const { ANALYTICS, MARKETING } = useFeatureFlags()
  return (
    <Routes>
      {ANALYTICS && <Route path="guide-starts" element={<GuideStarts />} />}
      {ANALYTICS && <Route path="first-opens" element={<FirstOpens />} />}
      {MARKETING && <Route path="asset-counts" element={<AssetCounts />} />}

      {MARKETING && <Route path="generate-qr-codes" element={<QrCodeGeneration />} />}
    </Routes>
  )
}
