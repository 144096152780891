import { APIProvider, Map, RenderingType } from '@vis.gl/react-google-maps'
import _ from 'lodash'
import { IMapJson } from 'shared/json/IMapJson'
import GoogleMapsMarker from 'client/screens/AppEditor/MapEditor/GoogleMapsMarker'
import { useEffect, useMemo, useState } from 'react'
import { GoogleMapsPinData } from 'client/types'

const { GOOGLE_MAPS_API_KEY } = window

const DEFAULT_ZOOM = 13

interface IGoogleMapsPreviewProps {
  map: Partial<IMapJson>
}

const GoogleMapsPreview = ({ map }: IGoogleMapsPreviewProps) => {
  const { mapLocations, cameraCenterLatitude, cameraCenterLongitude, cameraZoom, cameraHeading } =
    map
  const [googleMapsLocations, setGoogleMapsLocations] =
    useState<IMapJson['mapLocations']>(mapLocations)
  const [mapKey, setMapKey] = useState<string>(
    `${cameraCenterLatitude}-${cameraCenterLongitude}-${cameraZoom}-${cameraHeading}`
  )

  const centerAndZoomPair = useMemo(() => {
    // If camera center is provided, use it to render
    if (!_.isNil(cameraCenterLatitude) && !_.isNil(cameraCenterLongitude)) {
      return {
        defaultCenter: { lat: cameraCenterLatitude, lng: cameraCenterLongitude },
        defaultZoom: cameraZoom ?? DEFAULT_ZOOM,
        defaultBounds: undefined
      }
    }
    // otherwise render the globe view
    return {
      defaultBounds: {
        north: 90,
        south: -90,
        east: 180,
        west: -180
      },
      defaultCenter: undefined,
      defaultZoom: undefined
    }
  }, [cameraCenterLatitude, cameraCenterLongitude, cameraZoom])

  useEffect(() => {
    setGoogleMapsLocations(mapLocations)
  }, [mapLocations])

  const locations: GoogleMapsPinData[] = useMemo(
    () =>
      _(googleMapsLocations)
        .filter(
          (mapLocation) => !_.isNil(mapLocation?.latitude) && !_.isNil(mapLocation?.longitude)
        )
        .map(({ id, latitude, longitude }) => ({
          id: id!,
          key: `pin-${id}`,
          location: {
            lat: latitude!,
            lng: longitude!
          }
        }))
        .value(),
    [googleMapsLocations]
  )

  useEffect(() => {
    // change key prop of the Map component to force rerender to map when options change:
    // https://github.com/google-map-react/google-map-react/issues/76#issuecomment-164833277
    setMapKey(`${cameraCenterLatitude}-${cameraCenterLongitude}-${cameraZoom}-${cameraHeading}`)
  }, [cameraCenterLatitude, cameraCenterLongitude, cameraZoom, cameraHeading])

  return (
    <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
      <Map
        key={mapKey}
        {...centerAndZoomPair}
        heading={cameraHeading}
        mapId={`${map.id!}`}
        renderingType={RenderingType.VECTOR}
        // disable the Pegman on street view mode
        streetViewControl={false}
        // disable user interactions with other commercial pans in Google Maps
        clickableIcons={false}
        mapTypeControl={false}
        fullscreenControl={false}
      >
        <>
          {locations.map((location) => (
            <GoogleMapsMarker key={location.id} mapId={map.id!} location={location} />
          ))}
        </>
      </Map>
    </APIProvider>
  )
}

export default GoogleMapsPreview
