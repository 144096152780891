import styled from 'styled-components'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'

export const FormSection = styled.section`
  display: flex;
  column-gap: var(--spacing-medium);
`

export const PageContentContainer = styled.div`
  padding: var(--spacing-xlarge);
  > * + * {
    margin-top: var(--spacing-large);
  }
`

export const StyledSelectBox = styled(SelectBox)`
  color: black;
  width: 313px;
`
