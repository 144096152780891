import _ from 'lodash'
import { GQLCreator } from 'shared/graphql/types/graphql'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import gql from 'graphql-tag'
import { LANGUAGES_COLUMN, UPDATED_AT_COLUMN } from 'client/screens/Catalog/grids/shared/columns'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import EmptyStateGridCreatorSVG from 'client/assets/svg/emptyStates/empty_state_grid_creator.svg'
import GridPlusIconAddButton from './shared/GridPlusIconAddButton'
import CreatorForm from '../forms/CreatorForm'
import { EmptyStatePlaceholderDescription } from './shared/styledComponents'

const getDisplayName = (rowData: GQLCreator) => _.join([rowData.prefix, rowData.name], ' ').trim()

const displayNameCellRenderer = ({ rowData }: ITableCellProps<GQLCreator>) =>
  getDisplayName(rowData)

const CREATORS_GRAPHQL_QUERY = gql`
  query creatorsForMuseum($museumId: Int!) {
    museum(id: $museumId) {
      id
      creators {
        id
        prefix
        name
        lifedates
        externalId
        locales {
          code
          englishName
        }
        updatedAt
      }
    }
  }
`
const addCreatorButton = (
  <GridPlusIconAddButton label={t('Add Creator')} to="/catalog/creators/new" />
)

const CreatorsEmptyPlaceholder = () => (
  <>
    <Header3>{t('Add Creators')}</Header3>
    <EmptyStatePlaceholderDescription centerAlign={true}>
      <p>
        {t(
          'Add artists, performers, or makers to credit them as the Creators of Items in your guide.'
        )}
      </p>
      {addCreatorButton}
    </EmptyStatePlaceholderDescription>
  </>
)

export default function Creators() {
  return (
    <BaseGridView
      contentName="Creator"
      gqlQuery={CREATORS_GRAPHQL_QUERY}
      columns={_.compact([
        {
          dataKey: 'name',
          label: t('Name'),
          cellRenderer: displayNameCellRenderer,
          sortBy: (rowData) => getDisplayName(rowData)
        },
        { dataKey: 'lifedates', label: t('Life Dates') },
        LANGUAGES_COLUMN,
        UPDATED_AT_COLUMN
      ])}
      filterCriteria={['prefix', 'name', 'lifedates']}
      /* TODO: DRY this up */
      buttons={addCreatorButton}
      formComponent={CreatorForm}
      placeholder={<CreatorsEmptyPlaceholder />}
      placeholderIcon={<EmptyStateGridCreatorSVG />}
    />
  )
}
