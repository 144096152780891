import { GQLItem, GQLExhibit, GQLCreator } from 'shared/graphql/types/graphql'
import _ from 'lodash'
import { ItemType } from 'shared/constants/item'

const getCreatorFullName = ({ prefix, name, lifedates }: GQLCreator) =>
  _([prefix, name, lifedates && `(${lifedates})`])
    .compact()
    .join(' ')

type IContent = GQLItem | GQLExhibit

export function isItemContent(content: IContent): content is GQLItem {
  return content.__typename === 'Item' // eslint-disable-line no-underscore-dangle
}

export function isExhibitContent(content: IContent): content is GQLExhibit {
  return content.__typename === 'Exhibit' // eslint-disable-line no-underscore-dangle
}

interface IItemDescription {
  header: string
  subheaders?: string[]
}

interface IItemWithDescription
  extends Pick<GQLItem, 'type' | 'commonName' | 'scientificName' | 'title' | 'date'> {
  creators?: Pick<GQLCreator, 'prefix' | 'name' | 'lifedates'>[]
}

export function getItemDescription(
  item: IItemWithDescription,
  useFullCreatorName = false
): IItemDescription {
  const { type, commonName, scientificName, title, date, creators } = item
  if (type === ItemType.BOTANICAL) {
    const header = (commonName || scientificName) as string // db constraint says the value for one of these columns must be non-null
    const subheader = header === commonName ? scientificName : null
    return {
      header,
      subheaders: _.compact([subheader])
    }
  }
  const getCreatorDisplayName = useFullCreatorName
    ? getCreatorFullName
    : ({ name }: { name: GQLCreator['name'] }) => name
  const creatorNames = _.map(creators, getCreatorDisplayName).join(', ')
  return { header: title!, subheaders: _.compact([creatorNames, date]) }
}

export const getContentName = (thing: GQLExhibit | GQLItem): string =>
  // TODO there's an assumption that one of these is guaranteed to be present, but maybe we need a db constraint too?
  (thing.title || (thing as GQLItem).commonName || (thing as GQLItem).scientificName)!
