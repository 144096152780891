import {
  GraphQLBoolean,
  GraphQLInputObjectType,
  GraphQLInt,
  GraphQLList,
  GraphQLNonNull,
  GraphQLString
} from 'graphql'
import { RelatedContentInput } from 'shared/graphql/mutations/RelatedContentInput'
import { IdInput } from './types'

const ItemTranslationInput = new GraphQLInputObjectType({
  name: 'ItemTranslationInput',
  fields: {
    localeCode: {
      type: GraphQLString
    },
    title: {
      type: GraphQLString
    },
    secondaryTitle: {
      type: GraphQLString
    },
    information: {
      type: GraphQLString
    },
    medium: {
      type: GraphQLString
    },
    accessionNumber: {
      type: GraphQLString
    },
    credit: {
      type: GraphQLString
    },
    dimensions: {
      type: GraphQLString
    },
    date: {
      type: GraphQLString
    },
    commonName: {
      type: GraphQLString
    },
    bloomSeason: {
      type: GraphQLString
    },
    family: {
      type: GraphQLString
    },
    origin: {
      type: GraphQLString
    },
    range: {
      type: GraphQLString
    },
    flowers: {
      type: GraphQLString
    },
    leaves: {
      type: GraphQLString
    },
    fruit: {
      type: GraphQLString
    },
    bark: {
      type: GraphQLString
    },
    attributes: {
      type: GraphQLString
    }
  }
})

const ItemMapLocationInput = new GraphQLInputObjectType({
  name: 'ItemMapLocationInput',
  fields: {
    mapLocation: {
      type: new GraphQLNonNull(IdInput)
    },
    featured: {
      type: GraphQLBoolean
    }
  }
})

export const ItemInput = new GraphQLInputObjectType({
  name: 'ItemInput',
  fields: {
    type: {
      type: GraphQLString
    },
    lookupNumber: {
      type: GraphQLInt
    },
    published: {
      type: GraphQLBoolean
    },
    creators: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    scientificName: {
      type: GraphQLString
    },
    audios: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    videos: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    images: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    exhibits: {
      type: new GraphQLList(new GraphQLNonNull(IdInput))
    },
    itemMapLocation: {
      type: ItemMapLocationInput
    },
    relatedContent: {
      type: new GraphQLList(new GraphQLNonNull(RelatedContentInput))
    },
    translations: {
      type: new GraphQLList(new GraphQLNonNull(ItemTranslationInput))
    },
    linkedGuideId: {
      type: GraphQLInt
    }
  }
})
