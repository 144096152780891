import gql from 'graphql-tag'
import TranslationForm from 'client/components/TranslationForm/TranslationForm'
import ItemFormView from 'client/screens/Catalog/forms/ItemForm/FormView'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { ItemType } from 'shared/constants/item'
import { createApiConfig } from 'client/components/TranslationForm/util'
import useValidateFeatured from 'client/components/TranslationForm/useValidateFeatured'
import { ItemInput } from 'shared/graphql/mutations/ItemInput'
import { BotanicalItemSchemaClient, ItemSchemaClient } from 'client/validation/Item'

const apiConfig = createApiConfig(
  'item',
  gql`
    query getSingleItem($id: Int!) {
      item(id: $id) {
        id
        externalId
        uuid
        type
        lookupNumber
        linkedGuideId
        scientificName
        audios {
          id
        }
        exhibits {
          id
        }
        videos {
          id
        }
        creators {
          id
        }
        images {
          id
        }
        relatedContent {
          item {
            uuid
            id
          }
          exhibit {
            uuid
            id
          }
          relationshipType
        }
        itemMapLocation {
          mapLocation {
            id
          }
          featured
        }
        translations {
          title
          secondaryTitle
          commonName
          bloomSeason
          date
          medium
          dimensions
          family
          origin
          range
          flowers
          leaves
          fruit
          bark
          attributes
          credit
          accessionNumber
          information
          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

interface ItemFormProps {
  type: ItemType
}
const ItemForm = (props: ItemFormProps) => {
  const { type } = props
  const { validateDeleteFeatured } = useValidateFeatured('item', 'item')
  const contentId = useNumericRouteParam('id')

  return (
    <TranslationForm
      contentName={type === ItemType.BOTANICAL ? 'Botanical Item' : 'Item'}
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={type === ItemType.BOTANICAL ? BotanicalItemSchemaClient : ItemSchemaClient}
      defaultValues={{ type }}
      inputType={ItemInput}
      validateDelete={validateDeleteFeatured}
    >
      <ItemFormView />
    </TranslationForm>
  )
}
export default ItemForm
