import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { generateCoordinates } from 'shared/util/maps'
import { MAP_LOCATION_DEFAULT_RADIUS } from 'shared/constants/maps'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import useMapLocationContent from 'client/screens/AppEditor/MapEditor/useMapLocationContent'
import {
  LatitudeContextualHelp,
  LongitudeContextualHelp
} from 'client/screens/AppEditor/MapEditor/GoogleMapsContextualHelp'
import { GQLExhibit, GQLItem, GQLMapLocationContent } from 'shared/graphql/types/graphql'
import { t } from 'client/i18n'
import ContextualHelp from 'client/components/ContextualHelp/ContextualHelp'
import EnhancedStandardForm from 'client/components/StandardForm/EnhancedStandardForm'
import useNumericRouteParam from 'client/hooks/useNumericRouteParam'
import { NAME_CHAR_LIMIT, PinFormSchemaClient, PinSchemaCore } from 'client/validation/Map'
import TextInput from 'client/components/Formik/TextInput/TextInput'
import { BaseFormFieldSection } from 'client/components/Form/FormField/FormFieldSection'
import { useBuildings } from 'client/screens/AppEditor/MapEditor/useBuildings'
import { getInitialLatOrLong, getMapById } from 'client/screens/AppEditor/MapEditor/MapEditorUtils'
import { usePost, usePut } from 'client/hooks/api'
import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { confirm } from 'client/redux/actions/confirmation'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { useGuideCoordinates } from 'client/screens/AppEditor/MapEditor/useGuideCoordinates'
import EnhancedStandardFormResourceType from 'shared/EnhancedStandardFormResourceType'
import ReorderableLocationContentList from './ReorderableLocationContentList'
import useItemsAndExhibits from './useItemsAndExhibits'

interface ILocationFormProps {
  plane: {
    width: number
    height: number
  }
}

interface ILocationFormValues {
  name: string
  content: {
    // updateAllMapLocations is added in client.
    content: GQLMapLocationContent & { updateAllMapLocations?: boolean }
    position: number
    item?: GQLItem
    exhibit?: GQLExhibit
  }[]
  latitude?: number
  longitude?: number
}

interface ILocationFormWithItemsAndExhibitsProps extends ILocationFormProps {
  items: GQLItem[]
  exhibits: GQLExhibit[]
  isItemsAndExhibitsLoading: boolean
  isGoogleMap: boolean
}

const LocationNameContextualHelp = () => (
  <ContextualHelp
    helpSize="large"
    header={t('Location Name')}
    helpContent={
      <>
        <p>
          {t(
            'This name appears when you tap the pin on the map view and in the list view to reinforce the physical location.'
          )}{' '}
        </p>
        <p>
          {t(
            'You can enclose the name in square brackets to hide the name from the map (e.g., [Cafe]), but be aware that including a location name can help visitors find the content, especially visitors navigating via the list view.'
          )}
        </p>
      </>
    }
    tooltipContent={t('More on where the location name appears')}
  />
)

const LocationFormWithItemsAndExhibits = (props: ILocationFormWithItemsAndExhibitsProps) => {
  const { plane, items, exhibits, isItemsAndExhibitsLoading, isGoogleMap } = props
  const buildingId = useNumericRouteParam('buildingId')
  const mapId = useNumericRouteParam('floorId')
  const pinId = useNumericRouteParam('locationId')
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const isExterior = location.pathname.includes('/exterior')
  const { buildings, exteriorMap, loading: isQueryLoading } = useBuildings()
  const { GOOGLE_MAPS } = useFeatureFlags()
  const { guideLatitude, guideLongitude, loading: isFetchGuideCoordinates } = useGuideCoordinates()

  const [createPin, isCreatingPin] = usePost(`/maps/floor/${mapId}/pin`, {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: isExterior
            ? t("We weren't able to create the map pin. Please try again later.")
            : t("We weren't able to create the floor pin. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const [updatePinContent, isUpdatingPinContent] = usePut(
    `/maps/floor/${mapId}/pin/${pinId}/content`,
    {
      onSuccess: () => {
        refetchActiveQueries()
        dispatch(showChangesSavedToast())
      },
      onError: () => {
        dispatch(
          confirm({
            title: t('Unable to Save Changes'),
            message: isExterior
              ? t("We weren't able to update the map pin. Please try again later.")
              : t("We weren't able to update the floor pin. Please try again later."),
            isAlert: true
          })
        )
      }
    }
  )

  const floors = getMapById(buildings, exteriorMap, buildingId, mapId)

  const locationInfo = _.find(floors?.mapLocations, { id: pinId })
  const {
    name: initialName,
    mapLocationContents,
    latitude: initialLatitude,
    longitude: initialLongitude,
    loading: isFetchingMapLocationContent
  } = useMapLocationContent(pinId)

  const isLoading =
    isItemsAndExhibitsLoading ||
    isQueryLoading ||
    isCreatingPin ||
    isUpdatingPinContent ||
    isFetchingMapLocationContent ||
    isFetchGuideCoordinates

  const handleSubmit = async (values: ILocationFormValues) => {
    const { name, content: rawContent, latitude, longitude } = values
    const content = rawContent.map((contentArg) => ({
      exhibitId: contentArg.content.exhibit?.id,
      itemId: contentArg.content.item?.id,
      featured: contentArg.content.featured,
      updateAllMapLocations: contentArg.content.updateAllMapLocations
    }))

    const baseData = { name, content, latitude, longitude }
    if (locationInfo) {
      const locationData = { ...baseData, id: pinId }
      await updatePinContent(locationData)
    } else {
      const { x, y } = generateCoordinates(plane)
      const hasExhibits = _.some(content, 'exhibitId')
      const radius = _.size(content) > 1 || hasExhibits ? MAP_LOCATION_DEFAULT_RADIUS : 0
      const pinData = { ...baseData, x, y, radius }

      await createPin(pinData)
    }
    // TODO: need to handle navigate back for all map forms in EnhancedStandardForm
    navigate('..')
  }

  const content = locationInfo && !isLoading ? mapLocationContents : []

  const getLocationDescriptionText = () => {
    const message = GOOGLE_MAPS
      ? t(
          'You can set the pin’s location using latitude and longitude coordinates or by dragging the pin on the map.'
        )
      : t('You can set the pin’s location using latitude and longitude coordinates.')
    return isExterior && isGoogleMap
      ? message
      : t('You can set the pin’s location by dragging the pin on the map.')
  }

  const initialValues = {
    name: initialName,
    content,
    latitude: getInitialLatOrLong(
      isGoogleMap,
      initialLatitude,
      exteriorMap?.cameraCenterLatitude,
      guideLatitude,
      isExterior
    ),

    longitude: getInitialLatOrLong(
      isGoogleMap,
      initialLongitude,
      exteriorMap?.cameraCenterLongitude,
      guideLongitude,
      isExterior
    )
  }

  const PinFormView = () => {
    return (
      <>
        <BaseFormFieldSection
          label={t('Content')}
          description={t('A pin lets you attach content to a specific location on your map.')}
          hideDivider={true}
        >
          <ReorderableLocationContentList items={items} exhibits={exhibits} />
        </BaseFormFieldSection>

        <BaseFormFieldSection label={t('Location')} description={getLocationDescriptionText()} />
        <TextInput
          name="name"
          label={t('* Name')}
          placeholder={t('e.g., North side of reflecting pond, East Wing Gallery')}
          maxLength={NAME_CHAR_LIMIT}
          additionalLabelNode={LocationNameContextualHelp}
        />
        {isExterior && isGoogleMap && (
          <>
            <TextInput
              name="latitude"
              label={t('* Latitude')}
              additionalLabelNode={LatitudeContextualHelp}
            />
            <TextInput
              name="longitude"
              label={t('* Longitude')}
              additionalLabelNode={LongitudeContextualHelp}
            />
          </>
        )}
      </>
    )
  }
  return (
    <EnhancedStandardForm
      resourceType={EnhancedStandardFormResourceType.PIN}
      contentId={pinId}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isLoading={isLoading}
      validationSchema={isGoogleMap && isExterior ? PinFormSchemaClient : PinSchemaCore}
    >
      <PinFormView />
    </EnhancedStandardForm>
  )
}

const LocationForm = (props: ILocationFormProps) => {
  const {
    items,
    exhibits,
    isGoogleMap,
    loading: isItemsAndExhibitsLoading,
    error: itemsAndExhibitsError
  } = useItemsAndExhibits()

  if (itemsAndExhibitsError) {
    return <GQLErrorRenderer error={itemsAndExhibitsError} />
  }

  return (
    <LocationFormWithItemsAndExhibits
      items={items}
      exhibits={exhibits}
      isItemsAndExhibitsLoading={isItemsAndExhibitsLoading}
      isGoogleMap={isGoogleMap}
      {...props}
    />
  )
}

export default LocationForm
