import { PIN_ICON_HEIGHT, PIN_ICON_WIDTH } from 'client/screens/AppEditor/MapEditor/constants'
import { ICoordinates } from 'shared/util/maps'
import styled from 'styled-components'

interface IPinIcon {
  show: boolean
  offset: ICoordinates
}

export const PinIcon = styled.div<IPinIcon>`
  position: absolute;
  left: ${({ offset: { x = 0 } }) => x - PIN_ICON_WIDTH / 2}px;
  top: ${({ offset: { y = 0 } }) => y - PIN_ICON_HEIGHT}px;
  width: ${PIN_ICON_WIDTH}px;
  height: ${PIN_ICON_HEIGHT}px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  svg {
    width: ${PIN_ICON_WIDTH}px;
    height: ${PIN_ICON_HEIGHT}px;
  }
`
