import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import RichTextEditorField from 'client/components/Formik/RichTextEditor/RichTextEditor'
import { ElementType } from 'react'

interface ITitleInputProps {
  name: string
  label: string
  maxLength?: number
  additionalLabelNode?: ElementType
}
export function FlaggedRTEInputField(props: ITitleInputProps) {
  const { RTE_FIELDS } = useFeatureFlags()

  if (RTE_FIELDS) {
    return <RichTextEditorField {...props} mode="single" fieldContainer={FormField} />
  }
  return <TextInputFormField {...props} />
}
