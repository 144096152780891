import { useContext } from 'react'
import { ItemType } from 'shared/constants/item'
import RichTextEditorField from 'client/components/Formik/RichTextEditor/RichTextEditor'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationFormContext'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import RelatedContent from 'client/screens/Catalog/forms/shared/RelatedContent'
import AudiosList from 'client/components/PickerLists/AudiosList'
import VideosList from 'client/components/PickerLists/VideosList'
import ExhibitsList from 'client/components/PickerLists/ExhibitsList'

import MapLocation from 'client/screens/Catalog/forms/ItemForm/MapLocation'
import ItemLookupNumberSelect from 'client/screens/Catalog/forms/ItemForm/ItemLookupNumberSelect'
import ImagesListField from 'client/screens/Catalog/forms/shared/ImagesListField'
import Creators from 'client/screens/Catalog/forms/ItemForm/Creators'
import LinkedGuideSelectBoxField from 'client/screens/Catalog/forms/shared/LinkedGuideSelectBoxField'
import { t } from 'client/i18n'
import { FlaggedRTEInputField } from 'client/screens/Catalog/forms/shared/FlaggedRTEInputField'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

const TITLE_CHAR_LIMIT = 180
const COMMON_SCIENTIFIC_NAME_CHAR_LIMIT = 180

export default () => {
  const { getFieldName, getFieldValue } = useContext(TranslationFormContext)
  const itemType: ItemType = getFieldValue('type')
  const isBotanical = itemType === ItemType.BOTANICAL
  const { ITEM_SECONDARY_TITLE } = useFeatureFlags()

  return (
    <>
      <ImagesListField name={getFieldName('images')} />

      {isBotanical && (
        <>
          <TextInputFormField
            name={getFieldName('commonName')}
            label={t('Common Name')}
            description={t('Note: this field is required if Scientific Name is not provided')}
            maxLength={COMMON_SCIENTIFIC_NAME_CHAR_LIMIT}
          />

          <RichTextEditorField
            name={getFieldName('scientificName')}
            translatable={false}
            label={t('Scientific Name')}
            description={t('Note: this field is required if Common Name is not provided')}
            maxLength={COMMON_SCIENTIFIC_NAME_CHAR_LIMIT}
            mode="single"
            fieldContainer={FormField}
          />

          <TextInputFormField
            name={getFieldName('bloomSeason')}
            label={t('Bloom Season')}
            description={t(
              'Note: This field label is displayed in the app, in addition to the value.'
            )}
          />
        </>
      )}

      {itemType === ItemType.ITEM && (
        <>
          <FlaggedRTEInputField
            name={getFieldName('title')}
            label={t('* Title')}
            maxLength={TITLE_CHAR_LIMIT}
          />

          {ITEM_SECONDARY_TITLE && (
            <RichTextEditorField
              name={getFieldName('secondaryTitle')}
              label={t('Secondary Title')}
              maxLength={TITLE_CHAR_LIMIT}
              mode="single"
              fieldContainer={FormField}
            />
          )}

          <FormField label={t('Creator/Maker')} translatable={false}>
            <Creators name={getFieldName('creators')} />
          </FormField>

          <TextInputFormField name={getFieldName('date')} label={t('Creation Date/Period/Era')} />
          <FlaggedRTEInputField name={getFieldName('medium')} label={t('Materials/Medium')} />
        </>
      )}

      <RichTextEditorField
        name={getFieldName('information')}
        label={t('Description')}
        fieldContainer={FormField}
      />

      <LinkedGuideSelectBoxField />

      <FormFieldSection
        label={t('Additional Information')}
        description={t(
          'In this section, all field labels are displayed in the app, in addition to the values.'
        )}
      >
        {itemType === ItemType.ITEM && (
          <>
            <TextInputFormField name={getFieldName('dimensions')} label={t('Dimensions')} />
            <TextInputFormField
              name={getFieldName('credit')}
              label={t('Credit')}
              description={t('If needed, give credit to a donor, lender, fund, or endowment.')}
            />
          </>
        )}

        {isBotanical && (
          <>
            <TextInputFormField name={getFieldName('family')} label={t('Family')} />

            <TextInputFormField name={getFieldName('origin')} label={t('Origin')} />
            <TextInputFormField name={getFieldName('range')} label={t('Range/Geographic Region')} />

            <TextInputFormField name={getFieldName('flowers')} label={t('Flowers')} />
            <TextInputFormField name={getFieldName('leaves')} label={t('Leaves')} />
            <TextInputFormField name={getFieldName('fruit')} label={t('Fruit')} />
            <TextInputFormField name={getFieldName('bark')} label={t('Bark')} />
            <TextInputFormField name={getFieldName('attributes')} label={t('Attributes')} />
          </>
        )}

        <TextInputFormField name={getFieldName('accessionNumber')} label={t('Accession Number')} />
      </FormFieldSection>

      <FormFieldSection label={t('Audio')} translatable={false}>
        <FormField translatable={false}>
          <AudiosList name={getFieldName('audios')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Video')} translatable={false}>
        <FormField translatable={false}>
          <VideosList name={getFieldName('videos')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Add to Exhibition or Tour')} translatable={false}>
        <FormField translatable={false}>
          <ExhibitsList name={getFieldName('exhibits')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection
        label={t('Show on Map')}
        description={t(
          'Choose a location pin to help visitors find the content on your map. Manage your pins on the Map page.'
        )}
        translatable={false}
      >
        <FormField translatable={false}>
          <MapLocation name={getFieldName('itemMapLocation')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection translatable={false}>
        <FormField translatable={false}>
          <ItemLookupNumberSelect name={getFieldName('lookupNumber')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Related')} translatable={false}>
        <FormField translatable={false}>
          <RelatedContent name={getFieldName('relatedContent')} />
        </FormField>
      </FormFieldSection>
    </>
  )
}
