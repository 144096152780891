import _ from 'lodash'
import PageContent from 'client/components/PageContent/PageContent'
import Button from 'client/components/Button/Button'
import FormField from 'client/components/Form/FormField/FormField'
import { gql, useQuery } from '@apollo/client'
import { GQLGuide, GQLMuseum } from 'shared/graphql/types/graphql'
import { useMemo, useState } from 'react'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import { ISelectBoxOptions } from 'client/components/Form/SelectBox/SelectBox'
import MuseumVersionType from 'shared/MuseumVersionType'
import {
  FormSection,
  PageContentContainer,
  StyledSelectBox
} from 'client/screens/Developer/styledComponents'
import useDeveloperPost from './useDeveloperPost'

type SourceGuide = Pick<GQLGuide, 'id' | 'name' | 'visibilityType'>

const ALL_GUIDES = gql`
  query allGuides {
    guides {
      id
      name
      visibilityType
    }
  }
`
type SourceGuideWithVersions = {
  id: number
  versions: {
    id: GQLMuseum['id']
    title: GQLMuseum['title']
    archiveDate: GQLMuseum['archiveDate']
    versionType: MuseumVersionType
  }[]
}

const SOURCE_GUIDE_WITH_VERSIONS = gql`
  query sourceGuideWithVersions($id: Int!) {
    guide(id: $id) {
      versions {
        id
        title
        versionType
        archiveDate
      }
    }
  }
`

const CopyMuseumToGuide = () => {
  const [selectedSourceGuideOption, setSelectedSourceGuideOption] = useState<ISelectBoxOptions>()

  const [selectedSourceMuseumOption, setSelectedSourceMuseumOption] = useState<{
    value: number
    label: string
  }>()

  const [selectedDestinationGuideOption, setSelectedDestinationGuideOption] = useState<{
    value: number
    label: string
  }>()

  const { data: allGuidesData } = useQuery<{ guides: SourceGuide[] }>(ALL_GUIDES)
  const { data: sourceGuideWithVersionsData } = useQuery<{ guide: SourceGuideWithVersions }>(
    SOURCE_GUIDE_WITH_VERSIONS,
    {
      skip: !selectedSourceGuideOption,
      variables: { id: selectedSourceGuideOption?.value }
    }
  )

  const sourceGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .orderBy('name')
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} (${guide.id})`
        }))
        .filter((guide) => guide.value !== selectedDestinationGuideOption?.value)
        .value(),
    [allGuidesData?.guides, selectedDestinationGuideOption?.value]
  )

  const sourceMuseumOptions = useMemo(
    () =>
      _(sourceGuideWithVersionsData?.guide.versions)
        .orderBy([
          (a) => {
            const priority = {
              [MuseumVersionType.LIVE]: -1,
              [MuseumVersionType.PREVIEW]: 0,
              [MuseumVersionType.ARCHIVE]: 1
            }
            return priority[a.versionType]
          },
          'archiveDate'
        ])
        .map((version) => {
          const dateLabel =
            version.versionType === MuseumVersionType.ARCHIVE
              ? ` - ${new Date(version.archiveDate!).toLocaleString()}`
              : ''
          return {
            value: version.id,
            label: `${version.title} (${
              version.id
            }) - ${version.versionType.toUpperCase()}${dateLabel}`
          }
        })
        .value(),
    [sourceGuideWithVersionsData?.guide.versions]
  )

  const privateGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .filter((guide) => {
          return (
            guide.visibilityType === GuideVisibilityType.PRIVATE &&
            guide.id !== selectedSourceGuideOption?.value
          )
        })
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} (${guide.id})`,
          guideId: guide.id
        }))
        .sortBy('label')
        .value(),
    [allGuidesData?.guides, selectedSourceGuideOption]
  )

  const [copyMuseumToPrivateGuide] = useDeveloperPost(
    '/api/developer/copy-museum-to-private-guide',
    'Copy Museum to Private Guide',
    () => window.location.reload()
  )

  return (
    <>
      <div>
        <p>Important info:</p>
        <ul>
          <li>
            Existing draft of destination guide will be archived, new draft will be created with
            copied content from source guide. <b>Content will not be merged!</b>
          </li>
          <li>Content UUIDs will be regenerated</li>
          <li>Copy is done as a pgboss job, please check jobs table to confirm completion</li>
        </ul>
      </div>

      <FormSection>
        <FormField label="Source Guide">
          <StyledSelectBox
            placeholder="Select a guide..."
            options={sourceGuideOptions}
            value={selectedSourceGuideOption}
            onChange={(e) => {
              const entry = _.find(sourceGuideOptions, { value: e.target.value })
              setSelectedSourceGuideOption(entry)
              setSelectedSourceMuseumOption(undefined)
            }}
          />
        </FormField>
        <FormField label="Source Museum" key={selectedSourceMuseumOption?.value}>
          <StyledSelectBox
            isDisabled={_.isNil(selectedSourceGuideOption)}
            placeholder="Select a museum..."
            options={sourceMuseumOptions}
            value={selectedSourceMuseumOption}
            onChange={(e) => {
              const entry = _.find(sourceMuseumOptions, { value: e.target.value })
              setSelectedSourceMuseumOption(entry)
            }}
          />
        </FormField>
      </FormSection>

      <FormField label="Destination Private Guide">
        <StyledSelectBox
          placeholder="Select a guide..."
          options={privateGuideOptions}
          value={selectedDestinationGuideOption}
          onChange={(e) => {
            const entry = _.find(privateGuideOptions, { value: e.target.value })
            setSelectedDestinationGuideOption(entry)
          }}
        />
      </FormField>
      <FormField>
        <Button
          disabled={!selectedSourceMuseumOption?.value || !selectedDestinationGuideOption?.value}
          onClick={() => {
            copyMuseumToPrivateGuide({
              sourceMuseumId: selectedSourceMuseumOption?.value,
              destinationGuideId: selectedDestinationGuideOption?.value
            })
          }}
          label="Copy Source Museum to Destination Guide"
        />
      </FormField>
    </>
  )
}

export default function CopyGuide() {
  return (
    <PageContent title="Copy Guide">
      <PageContentContainer>
        <CopyMuseumToGuide />
      </PageContentContainer>
    </PageContent>
  )
}
