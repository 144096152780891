import { useRef } from 'react'
import * as React from 'react'
import { getSuccessConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import MapDialog from 'client/screens/AppEditor/MapEditor/MapDialog'
import { DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import _ from 'lodash'
import { useExteriorMap } from 'client/screens/AppEditor/MapEditor/useExteriorMap'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import MapImageInput from './MapImageInput'

interface IExteriorMapUploadProps {
  onCancel: () => void
  onFileChange: (file: File) => void
  message: React.ReactNode
  title: React.ReactNode
  isOptOutGoogleMaps: boolean
  onAddGoogleMaps: () => void
}

export const ExteriorMapUpload = (props: IExteriorMapUploadProps) => {
  const { onCancel, onFileChange, message, title, isOptOutGoogleMaps, onAddGoogleMaps } = props
  const ref = useRef<HTMLInputElement>(null)
  const { GOOGLE_MAPS } = useFeatureFlags()

  const getLabel = () => {
    if (!GOOGLE_MAPS || isOptOutGoogleMaps) {
      return t('Upload Map')
    }
    return t('Add Map')
  }

  const onAddMap = () => {
    if (!GOOGLE_MAPS || isOptOutGoogleMaps) {
      ref.current?.click()
    } else {
      onAddGoogleMaps()
    }
  }

  return (
    <MapDialog>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>
        <MapImageInput id="create-exterior-map" onFileChange={onFileChange} ref={ref} />
        <span>{message}</span>
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button onClick={onAddMap} type="primary" label={getLabel()} />
      </DialogActions>
    </MapDialog>
  )
}

interface IExteriorMapSuccessProps {
  onContinue?: () => void
  label: string
}
export const ExteriorMapSuccess = ({ onContinue, label }: IExteriorMapSuccessProps) => {
  const { exteriorMap } = useExteriorMap()
  const isGoogleMap = exteriorMap?.isGoogleMap ?? false
  const buildings = useBuildingList()
  const hasBuildings = !_.isEmpty(buildings)
  const { GOOGLE_MAPS } = useFeatureFlags()
  const { message, title } = getSuccessConfirmationMessage(isGoogleMap, hasBuildings, GOOGLE_MAPS)

  return (
    <MapDialog>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>{message}</DialogBody>
      <DialogActions>
        <Button onClick={onContinue} type="primary" label={label} />
      </DialogActions>
    </MapDialog>
  )
}
