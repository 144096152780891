import Button from 'client/components/Button/Button'
import GridPlusIconAddButton from 'client/screens/Catalog/grids/shared/GridPlusIconAddButton'
import { GQLGuide, GQLUser } from 'shared/graphql/types/graphql'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import gql from 'graphql-tag'
import _ from 'lodash'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { format } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'
import UserMFAType from 'shared/UserMFAType'
import { Trans } from 'react-i18next'
import UserForm from './UserForm/UserForm'
import OverflowMenu from './OverflowMenu'
import MFATypeDisplayName from './MFATypeDisplayName'

function userDisplayName(user: GQLUser) {
  const { lastName, firstName } = user
  return _.compact([lastName, firstName]).join(', ')
}
function nameValue({ rowData }: ITableCellProps<GQLUser>) {
  return userDisplayName(rowData)
}

function guidesDisplayValue(guides: GQLGuide[]) {
  return _.map(guides, 'name').join(', ')
}

function guidesRenderer({ cellData }: ITableCellProps<GQLUser>) {
  const guideNames = guidesDisplayValue(cellData)
  return (
    <Tooltip content={guideNames}>
      <div>{guideNames}</div>
    </Tooltip>
  )
}

const USERS_GRAPHQL_QUERY = gql`
  query users {
    users {
      id
      email
      firstName
      lastName
      permissionType
      mfaType
      assignedGuides {
        id
        name
      }
      mfaEnabled
      canResendPlatformInvite
      lastLogin
    }
  }
`

const dateField = ({ cellData }: ITableCellProps<GQLUser>) =>
  cellData && format(cellData, UNIVERSAL_DATE_FORMAT)

const Placeholder = () => (
  <>
    <p>{t('No Users have been added yet.')}</p>
    <p>
      <Trans i18nKey="Click __addText__ to continue." values={{ addText: t('Add User') }} />
    </p>
  </>
)

export default () => {
  const { EMAIL_MFA } = useFeatureFlags()
  return (
    <BaseGridView
      contentName="User"
      gqlQuery={USERS_GRAPHQL_QUERY}
      columns={_.compact([
        {
          dataKey: 'name',
          label: t('Name'),
          cellRenderer: nameValue,
          sortBy: userDisplayName
        },
        { dataKey: 'email', label: t('Email') },
        {
          dataKey: 'assignedGuides',
          label: t('Guides'),
          cellRenderer: guidesRenderer,
          sortBy: (rowData: GQLUser) => guidesDisplayValue(rowData.assignedGuides)
        },
        { dataKey: 'permissionType', label: t('Permission Type') },
        { dataKey: 'lastLogin', label: 'Last Login', cellRenderer: dateField },
        EMAIL_MFA && {
          dataKey: 'mfaType',
          label: 'MFA Type',
          cellRenderer: ({ cellData }: ITableCellProps<GQLUser, UserMFAType>) =>
            cellData ? MFATypeDisplayName[cellData] : null
        },
        {
          dataKey: '',
          sortable: false,
          cellRenderer: ({ rowData }: ITableCellProps<GQLUser>) => <OverflowMenu user={rowData} />,
          width: 50,
          flexGrow: 0
        }
      ])}
      filterCriteria={['firstName', 'lastName', 'email', 'permissionType']}
      buttons={
        <>
          <Button
            onClick={() => window.open('/api/users/export')}
            type="secondary"
            label={t('Export as CSV')}
          />
          <GridPlusIconAddButton label={t('Add User')} to="/admin/users/new" />
        </>
      }
      formComponent={UserForm}
      placeholder={<Placeholder />}
    />
  )
}
