import { refetchActiveQueries } from 'client/apollo'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { IBuildingById } from 'client/types'
import api from 'client/util/api'
import _ from 'lodash'
import { Dispatch } from 'redux'
import { IMapJson } from 'shared/json/IMapJson'
import { confirm } from 'client/redux/actions/confirmation'
import { t } from 'client/i18n'
import { ICoordinates } from 'shared/util/maps'

/**
 * A helper function that mimics `useSelector(getMapById)`, but not relying on redux state.
 *
 * This is useful to keep the original logic as we slowly migrate states off redux.
 */
export const getMapById = (
  buildingsMap: IBuildingById,
  exterior?: IMapJson,
  buildingId?: number,
  floorId?: number
): IMapJson => {
  if (exterior && exterior?.id === floorId) {
    return exterior
  }

  if (buildingId) {
    return _.find(buildingsMap[buildingId]?.floors, { id: floorId })!
  }

  const buildings = _.values(buildingsMap)
  const allMaps = _.reduce(
    buildings,
    (acc, building) => [...acc, ...building.floors],
    [] as typeof buildings[0]['floors']
  )

  return _.find(allMaps, { id: floorId })!
}

export const createFormData = (obj: any) => {
  const form = new FormData()
  _.each(obj, (value, key) => {
    if (!_.isUndefined(value)) {
      form.append(key, value)
    }
  })
  return form
}

interface ISetPinForMapLocations {
  id: number
  coordinates?: ICoordinates
  radius?: number
  map: Partial<IMapJson>
  latLng?: google.maps.LatLngLiteral
}

export const setPinForMapLocations = (props: ISetPinForMapLocations) => {
  const { id, coordinates, radius, map } = props
  // we have to update the local state to avoid UI flip
  const locationIndex = _.findIndex(map.mapLocations, { id })
  const mapLocations = _.cloneDeep(map.mapLocations)

  if (locationIndex >= 0) {
    // create a cloned mapLocations here to avoid changing floor state directly inside setFloor
    mapLocations![locationIndex] = {
      ...mapLocations![locationIndex],
      ...(radius ? { radius } : {}),
      ...(coordinates ? { xPosition: coordinates.x } : {}),
      ...(coordinates ? { yPosition: coordinates.y } : {})
    }
  }

  return mapLocations
}

interface IUpdateFloorPinData {
  id: number
  coordinates?: ICoordinates
  radius?: number
  latitude?: google.maps.LatLngLiteral['lat']
  longitude?: google.maps.LatLngLiteral['lng']
}

export const updatePin = async (
  floorId: number,
  pinData: IUpdateFloorPinData,
  dispatch: Dispatch
) => {
  const { id: pinId, coordinates, radius, latitude, longitude } = pinData
  const locationPropertiesToUpdate = _.omitBy(
    {
      xPosition: _.isFinite(coordinates?.x) ? Math.round(coordinates!.x) : undefined,
      yPosition: _.isFinite(coordinates?.y) ? Math.round(coordinates!.y) : undefined,
      radius: _.isFinite(radius) ? Math.round(radius!) : undefined,
      latitude: _.isFinite(latitude) ? latitude : undefined,
      longitude: _.isFinite(longitude) ? longitude : undefined
    },
    _.isUndefined
  )

  await api
    .put(`/maps/floor/${floorId}/pin/${pinId}`, locationPropertiesToUpdate)
    .then(() => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    })
    .catch(() => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to update the pin position. Please try again later."),
          isAlert: true
        })
      )
    })
}

export const getInitialLatOrLong = (
  isGoogleMapEnabled: boolean,
  initialLatOrLong: number | null | undefined,
  cameraLatOrLong: number | null | undefined,
  guideLatOrLong: number | null | undefined,
  isExteriorMap: boolean = true
) => {
  if (!isGoogleMapEnabled || !isExteriorMap) {
    return undefined
  }
  if (!_.isNil(initialLatOrLong)) {
    return initialLatOrLong
  }
  if (!_.isNil(cameraLatOrLong)) {
    return cameraLatOrLong
  }
  if (!_.isNil(guideLatOrLong)) {
    return guideLatOrLong
  }
  return 0
}
