import { useDispatch } from 'react-redux'
import _ from 'lodash'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { confirm } from 'client/redux/actions/confirmation'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import { getDeleteMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/buildings'
import { t } from 'client/i18n'
import { useNavigate } from 'react-router-dom'
import { refetchActiveQueries } from 'client/apollo'
import { useDelete } from 'client/hooks/api'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'

interface ITooltipMenuProps {
  locationId: number
  isShowingBubble?: boolean
  onToggleBubble?: () => void
}

const BuildingMapLocationTooltipMenu = (props: ITooltipMenuProps) => {
  const { locationId, isShowingBubble, onToggleBubble } = props
  const shouldRenderHideShowOption = !_.isUndefined(isShowingBubble) && !!onToggleBubble
  const dispatch = useDispatch()

  // TODO: Link pin to building id. Buildings table might need a building id
  const buildings = useBuildingList()
  const building = _.find(buildings, { mapLocationId: locationId })!
  const buildingId = building?.id
  const navigate = useNavigate()

  const [deleteBuilding, isDeletingBuilding] = useDelete(`/maps/building/${buildingId}`, {
    onSuccess: () => {
      refetchActiveQueries()
      dispatch(showChangesSavedToast())
    },
    onError: () => {
      dispatch(
        confirm({
          title: t('Unable to Save Changes'),
          message: t("We weren't able to delete the building. Please try again later."),
          isAlert: true
        })
      )
    }
  })

  const handleEditBuilding = () => {
    navigate(`buildings/${buildingId}/edit`)
  }

  const handleDeleteBuilding = () => {
    const hasContent = building.floors.length > 0
    const { title, message } = getDeleteMessage(hasContent)

    dispatch(
      confirm({
        title,
        message,
        confirmYes: {
          action: async () => {
            await deleteBuilding()
          },
          label: t('Delete Building'),
          isDestructive: true
        }
      })
    )
  }

  const options: IMenuOption[] = _.compact([
    {
      label: t('Edit Building'),
      onClick: handleEditBuilding
    },
    shouldRenderHideShowOption
      ? {
          label: isShowingBubble ? t('Hide Area') : t('Show Area'),
          onClick: () => onToggleBubble()
        }
      : null,
    {
      label: t('Delete Building'),
      onClick: handleDeleteBuilding
    }
  ])

  return (
    <>
      <MenuPopout options={options} />
      {isDeletingBuilding && <LoadingOverlay />}
    </>
  )
}

export default BuildingMapLocationTooltipMenu
