import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import gql from 'graphql-tag'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const GUIDE_STARTS_QUERY = gql`
  query guideStarts {
    guideStartsReport {
      date
      guide
      starts
    }
  }
`

const Placeholder = () => (
  <>
    <p>{t('No Guide Starts have been added yet.')}</p>
    <p>
      <Trans i18nKey="Click __addText__ to continue." values={{ addText: t('Add Guide Start') }} />
    </p>
  </>
)

export default function GuideStarts() {
  return (
    <BaseGridView
      contentName="Guide Start"
      gqlQuery={GUIDE_STARTS_QUERY}
      columns={[
        { dataKey: 'date', label: t('Date') },
        { dataKey: 'guide', label: t('Guide') },
        { dataKey: 'starts', label: t('Starts') }
      ]}
      placeholder={<Placeholder />}
    />
  )
}
