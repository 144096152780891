import GuideForm from 'client/screens/Admin/Guides/GuideForm/GuideForm'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import { GQLGuide } from 'shared/graphql/types/graphql'
import gql from 'graphql-tag'
import GridPlusIconAddButton from 'client/screens/Catalog/grids/shared/GridPlusIconAddButton'
import _ from 'lodash'
import { t } from 'client/i18n'
import { format } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'
import { Trans } from 'react-i18next'

const dateField = ({ cellData }: ITableCellProps<GQLGuide>) =>
  cellData && format(cellData, UNIVERSAL_DATE_FORMAT)

const GUIDES_GRAPHQL_QUERY = gql`
  query guides {
    guides {
      id
      name
      code
      visibilityType
      publicDate
      liveDate
      isGoogleTranslateEnabled
      isShareEnabled
      browseCategories {
        name
      }
    }
  }
`

type BrowseFilterColumn = keyof GQLGuide & 'browseCategories'

const Placeholder = () => (
  <>
    <p>{t('No Guides have been added yet.')}</p>
    <p>
      <Trans i18nKey="Click __addText__ to continue." values={{ addText: t('Add Guide') }} />
    </p>
  </>
)

const browseFilterNames = (col: BrowseFilterColumn) => (rowData: GQLGuide) =>
  _(rowData[col]).map('name').join(', ')

const browseFiltersRenderer =
  (col: BrowseFilterColumn) =>
  ({ rowData }: ITableCellProps<GQLGuide>) =>
    browseFilterNames(col)(rowData)

const Guides = () => {
  return (
    <BaseGridView
      contentName="Guide"
      gqlQuery={GUIDES_GRAPHQL_QUERY}
      columns={_.compact([
        { dataKey: 'name', label: t('Name') },
        { dataKey: 'code', label: t('Guide Code') },
        { dataKey: 'visibilityType', label: t('Visibility Type') },
        { dataKey: 'publicDate', label: t('Public Date'), cellRenderer: dateField },
        { dataKey: 'liveDate', label: t('Live Date'), cellRenderer: dateField },
        {
          dataKey: 'isGoogleTranslateEnabled',
          label: t('GT Enabled')
        },
        {
          dataKey: 'isShareEnabled',
          label: t('Share Enabled')
        },
        {
          dataKey: 'browseCategories',
          label: t('Categories'),
          cellRenderer: browseFiltersRenderer('browseCategories'),
          sortBy: browseFilterNames('browseCategories')
        }
      ])}
      filterCriteria={['name', 'visibilityType', 'browseCategories.name', 'code']}
      buttons={<GridPlusIconAddButton label={t('Add Guide')} to="/admin/guides/new" />}
      formComponent={GuideForm}
      placeholder={<Placeholder />}
    />
  )
}

export default Guides
