import { Route, Routes } from 'react-router-dom'
import Actions from 'client/screens/Developer/Actions'
import AppsflyerLinkEditor from 'client/screens/Developer/AppsflyerLinkEditor'
import CopyGuide from 'client/screens/Developer/CopyGuide'
import ChangeGuideDefaultLocale from 'client/screens/Developer/ChangeGuideDefaultLocale'

export default function Developer() {
  return (
    <Routes>
      <Route path="actions" element={<Actions />} />
      <Route path="copy-guide" element={<CopyGuide />} />
      <Route path="change-default-locale" element={<ChangeGuideDefaultLocale />} />
      <Route path="appsflyer-link-editor" element={<AppsflyerLinkEditor />} />
    </Routes>
  )
}
